.library-layout {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.upload-layout {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
}

.drag-drop-area {
  padding: 20px;
  gap: 1rem;
  background-color: var(--primary-bg-light);
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.custom-dragger .ant-upload-drag {
  border: none !important;
}
.custom-dragger .ant-upload-btn {
  padding: 0 !important;
}
.list-layout {
}

.list-layout .title {
  font-size: 18px;
  color: var(--primary-text-light);
  font-weight: 500;
}

.line_dashed_custom {
  border: none;
  height: 1.5px;
  background: #000;
  background: repeating-linear-gradient(90deg, #d9d9d9, #d9d9d9 6px, transparent 6px, transparent 10px);
}

.custom-collape .ant-collapse-content-active {
  max-height: 200px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .drag-drop-area {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .drag-drop-area {
    width: 600px;
  }
}

.ant-progress .ant-progress-inner {
  background-color: white !important;
  border-radius: 1rem !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

:root {
    --primary-color: #FF5C00;
    --secondary-color: #FFB348;
    --disabled-color: #D9D9D9;
    --primary-text-color: #FFFDFD; /* text */
    --primary-text-dark: #313131; /* background */
    --primary-bg-dark: #313131; /* background */
    --secondary-bg-dark: #4A4A4A; /* header */
    --primary-bg-light: #FFFDFD; /* sidebar */
    --select-bg-color: #FFF5F0;
    --disabled-bg-color: #D9D9D9;
    --primary-foreground: #f8f4f3;
    --secondary-foreground: #faf7f6;
    --secondary-background: rgba(128, 128, 128, 0.08);
    --radius: 6px;
}

body, html {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    background-color: var(--primary-bg-dark);
}

* {
    box-sizing: border-box;
    scrollbar-width: none;
}

.ant-table-body {
    scrollbar-width: thin;
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: inherit;
}

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

button,
[role="button"] {
    cursor: pointer;
}

.hide-upload-list-item .ant-upload-list-item-container {
    display: none !important;
}

.role-table .ant-table-thead .ant-table-cell:before {
    width: 0 !important;
}
.custom_popover_upload {
  position: absolute;
  left: -30px;
  top: 0;
  width: fit-content;
  background-color: var(--primary-bg-light);
  border-left: 5px solid var(--primary-color);
  border-radius: 0 5px 5px 0;
  padding: 20px;
}

.custom_popover_upload .text-content {
  font-size: 14px !important;
}

.custom_popover_upload .close-btn {
  background-color: transparent;
  border: none;
  box-shadow: unset;
  position: absolute;
  right: -15px;
  top: -15px;
}
.auth-section {
  background-color: var(--primary-bg-dark);
  color: var(--primary-text-color) !important;
  width: 100%;
  height: 100lvh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.auth-layout {
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.auth-description {
  background-color: transparent;
  color: var(--primary-text-color) !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 30px;
  height: 100%;
  width: 100%;
}

.auth-form {
  height: 100%;
  background-color: var(--primary-bg-light);
  color: black;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 3px solid var(--primary-color);
}

.custom_label {
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .auth-description {
    padding: 50px 50px 0 0;
    justify-content: start;
  }
  .auth-form {
    padding-top: 50px;
    justify-content: start;
  }
}

@media (min-width: 992px) {
  .auth-layout {
    width: 992px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}

@media (max-width: 768px) {
  .auth-description {
    display: none;
  }
}

.ant-pagination-item-active a {
  color: var(--primary-text-color) !important;
}
.ant-pagination-item-active {
  border-color: var(--primary-text-color) !important;
}

.ant-pagination-item-link {
  color: var(--primary-text-color) !important;
}

.ant-pagination-item a {
  color: var(--primary-text-color) !important;
}

.ant-table-filter-column {
  justify-content: start !important;
}

.ant-table-column-title {
  flex: none !important;
}
.ant-table-filter-trigger {
  padding: 0px !important;
  margin-inline: 0px !important;
}

.anticon-search {
  color: var(--primary-text-dark) !important;
}
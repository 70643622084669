main.active {
  margin-left: 0;
  width: 100%;
}

.head-active {
  margin-left: 0;
  width: 100%;
}

.cancel-button {
  background-color: #d9d9d9;
  color: #151515;
}

.cancel-button:hover {
  background-color: #d9d9d9 !important;
  color: #151515 !important;
}

.sop-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.sop-button:hover {
  background-color: var(--primary-text-color) !important;
  color: var(--primary-color) !important;
  border: solid 1px var(--primary-color) !important;
}

.sop-button-outlined {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border: solid 1px var(--primary-color) !important;
}

.sop-button-outlined:hover {
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
}

.sop-button-outlined:disabled {
  opacity: 0.3;
}

.login-button {
  background-color: var(--primary-color);
  min-width: 180px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 500;
  border-radius: 100px;
  transition: all 0.3s;
  padding: 0 30px;
}

.login-button:hover {
  background-color: var(--primary-text-color) !important;
  color: var(--primary-color) !important;
  border: solid 2px var(--primary-color) !important;
}

.label-text {
  font-size: 16px;
  font-weight: 500;
}

.link-text {
  color: var(--primary-color);
  font-size: 16px;
}

.custom-form label {
  height: auto !important;
}

.page-describe .title {
  color: var(--primary-text-color) !important;
}

.page-describe .subtitle {
  font-size: 24px;
  font-weight: normal;
  color: var(--primary-text-color) !important;
}

.font-16-400 {
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 400;
}

.select-options-layout {
  transition: max-height 0.3s;
}

.custom-drawer {
  transition: transform 0.3s ease-in;
}

.translateX-100 {
  transform: translateX(110%);
}

.translateX-0 {
  transform: translateX(0%);
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.text-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.on-hover-color {
  background-color: white;
}

.on-hover-color:hover {
  background-color: #fff5f0; 
}

.logo-font {
  user-select: none;
  text-wrap: nowrap;
  font-size: 28px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

.ant-empty-image > svg > g > g {
  stroke: white;
}
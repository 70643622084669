.input-transparent {
  color: var(--primary-text-color);
  border-color: var(--primary-text-color);
  background-color: transparent;
  padding: 0.75rem;
  border-width: 1px;
}

.input-transparent:hover,
.input-transparent:focus {
  border-color: var(--primary-text-color);
  border-width: 1px;
  background-color: transparent;
}

.ant-input-status-error {
  background-color: transparent !important;
}

.input-transparent::placeholder {
  color: var(--primary-text-color);
}

.ant-select-selection-item {
  color: var(--primary-text-color) !important;
}
.Select-Transparent .ant-select-selector {
  border-color: var(--primary-text-color) !important;
  border-width: 1px !important;
}

.custom-role {
  color: var(--primary-text-color) !important;
}


.ant-select-status-error .custom-role {
  color: red !important;
}


.send-link-text {
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-style: italic;
}
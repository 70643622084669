.email-custom-input {
  border-color: black !important;
  color: black;
}

.email-custom-input:hover {
  border-color: black !important;
}

.email-custom-input:focus {
  border-color: black !important;
  border-width: 1px !important;
}

.email-custom-input::placeholder {
  color: black;
}
.ant-input-status-error {
  border-color: red !important;
  color: red !important;
}

.ant-input-status-error:focus,
.ant-input-status-error:hover {
  border-color: red !important;
}

.email-custom-disable{
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}


.email-custom-disable:hover{
  border-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}
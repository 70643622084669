.select-border-custom .ant-select-selection-item {
  color: black !important;
}

.select-border-custom .ant-select-selector {
  border-color: black !important;
}

.ant-select-status-error .ant-select-selector{
  border-color: red !important;
}

.ant-select-status-error .ant-select-selection-item::placeholder {
  color: red !important;
}

.ant-select-status-error .ant-select-arrow svg {
  color: red !important;
}

.placeholder-custom{
  color: black !important;
}

.ant-select-status-error .placeholder-custom{
  color: red !important;
}